import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { SyncOutlined } from "@ant-design/icons";
import { Button, Drawer, theme } from "antd";
import { useFilterContext } from "context/FilterContext/Filter.context";
import { MatchingParamsType } from "router/navigationTypes";
import { useTranslations } from "translations/useTranslations";

import { FacetsModule } from "modules/FacetsModule";
import { useFacetsSearchParams } from "modules/FacetsModule/hooks/useFacetsSearchParams";
import { useInitialFacetsServiceLoading } from "modules/FacetsModule/hooks/useInitialFacetsServiceLoading";
import { MATCHING_FACETS_KEY } from "modules/MatchingModule/consts/matchingFacets";
import { useMatchingUrlQueryService } from "modules/MatchingModule/helpers/useMatchingUrlQueryService";
import { useGenerateMatchingFacetsApiUrl } from "modules/MatchingModule/hooks/useGenerateMatchingFacetsApiUrl";
import { useMatchingFacetsHeight } from "modules/MatchingModule/MatchingGroupsModule/hooks/useMatchingFacetsHeight";
import { useMatchingTargetsDrawerContext } from "modules/MatchingModule/MatchingListModule/components/MatchingTargetsDrawer/MatchingTargetsDrawer.context";

import { MatchingFacetsProps } from "./MatchingFacets.types";

import styles from "./MatchingFacets.module.scss";

export const MatchingFacets = React.memo<MatchingFacetsProps>(({ isOpen, onClose }) => {
  const translations = useTranslations();
  const { token } = theme.useToken();

  const { jobId = "", datasetName = "" } = useParams<MatchingParamsType>();

  const urlQueryService = useMatchingUrlQueryService();
  const { allowedKeys, getAllowedFilterParamsWithExcludedKeys, facetSectionsConfiguration } = useFilterContext();

  const { facetsService } = useInitialFacetsServiceLoading(
    MATCHING_FACETS_KEY,
    urlQueryService,
    facetSectionsConfiguration
  );
  const { resetFacetsSearchParams } = useFacetsSearchParams(facetsService, urlQueryService);

  const { isDrawerOpen } = useMatchingTargetsDrawerContext();
  const height = useMatchingFacetsHeight(isDrawerOpen);

  const generateApiUrl = useGenerateMatchingFacetsApiUrl(jobId, datasetName);
  const generateApiUrlWrapper = useCallback(
    (facetKey: string) => {
      return generateApiUrl(facetKey, getAllowedFilterParamsWithExcludedKeys([facetKey]));
    },
    [generateApiUrl, getAllowedFilterParamsWithExcludedKeys]
  );

  return (
    <div>
      <Drawer
        title={translations["matching.facets.title"]}
        placement="left"
        closable={true}
        onClose={onClose}
        open={isOpen}
        getContainer={false}
        className={styles.drawer}
        mask={false}
        width={264}
        headerStyle={{ padding: "20px 12px" }}
        contentWrapperStyle={{
          position: "sticky",
          top: 0,
          ...(height && { height }),
          borderRadius: `${token.borderRadius}px 0 0 ${token.borderRadius}px`,
          overflow: "hidden",
        }}
        extra={
          <Button
            icon={<SyncOutlined />}
            size="small"
            onClick={resetFacetsSearchParams} />
        }
      >
        <FacetsModule
          allowedKeys={allowedKeys}
          facetsKey={MATCHING_FACETS_KEY}
          generateApiUrl={generateApiUrlWrapper}
          urlQueryService={urlQueryService}
        />
      </Drawer>
    </div>
  );
});
