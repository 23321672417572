import { TaxonomyConfiguration } from "types/appConfig";

import React, { useCallback, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { FilterOutlined, SyncOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Drawer, theme, Typography } from "antd";
import clsx from "clsx";
import { queries } from "consts";
import { useFilterContext } from "context/FilterContext/Filter.context";
import { useIsPath } from "router/hooks/useIsPath";
import { ListOfTaxonomyParamsType } from "router/navigationTypes";
import { configurationServiceInstance } from "services/ConfigurationService";
import { useTranslations } from "translations/useTranslations";

import { getFacetsByTaxonomyClass } from "./helpers/getFacetsByTaxonomyClass";

import { useCatalogContext } from "modules/CatalogModule/CatalogModule.context";
import { useFacetsSearchParams } from "modules/FacetsModule/hooks/useFacetsSearchParams";
import { useInitialFacetsServiceLoading } from "modules/FacetsModule/hooks/useInitialFacetsServiceLoading";
import { TaxonomyBreadcrumbs } from "modules/TaxonomyModule/components/TaxonomyBreadcrumbs";
import { useTaxonomyUrlQueryService } from "modules/TaxonomyModule/hooks/useTaxonomyUrlQueryService";
import { useLastListSearch } from "modules/TaxonomyModule/ListOfTaxonomyModule/hooks/useLastListSearch";
import { TaxonomyTableModule } from "modules/TaxonomyModule/TaxonomyTableModule";

import { TaxonomySearch } from "./components/TaxonomySearch";
import { TaxonomyListFilterProvider } from "./TaxonomyListFilterProvider";

import styles from "./ListOfTaxonomyModule.module.scss";

// TODO: refactoring component. Reduce complexity.
export const ListOfTaxonomyModule = () => {
  const translations = useTranslations();
  const { taxonomyClass = "" } = useParams<ListOfTaxonomyParamsType>();

  const minLG = useMediaQuery({ query: queries.lg });
  const minMD = useMediaQuery({ query: queries.md });

  const closeFacets = useCallback(() => setIsFacetsOpen(false), []);
  const toggleFacets = useCallback(() => setIsFacetsOpen((prev) => !prev), []);

  const { isInCatalog, isInMatching } = useIsPath();
  const { getFacetsPos, components: { ListHeaderControls } } = useCatalogContext();
  const facets = useMemo(() => getFacetsByTaxonomyClass(taxonomyClass), [taxonomyClass]);

  const taxonomyConfig = configurationServiceInstance.getTaxonomyItemByName(taxonomyClass) as TaxonomyConfiguration;

  const urlQueryService = useTaxonomyUrlQueryService();
  const handleClearFacet = React.useCallback(
    (facetKey: string) => {
      urlQueryService.setSearchParam(facetKey, []);
    },
    [urlQueryService]
  );
  useLastListSearch(true);

  const [isFacetsOpen, setIsFacetsOpen] = useState(isInCatalog && minLG && facets.isFacetsExist);
  const isFacetPopover = isInMatching || !minLG;

  const title = taxonomyConfig.title || taxonomyClass;
  const showListHeader = isInCatalog || ListHeaderControls;

  const { token } = theme.useToken();

  const { facetSectionsConfiguration } = useFilterContext();
  const { getFacetsKey } = useCatalogContext();

  const { facetsService } = useInitialFacetsServiceLoading(
    getFacetsKey(taxonomyClass),
    urlQueryService,
    facetSectionsConfiguration
  );
  
  const { resetFacetsSearchParams } = useFacetsSearchParams(facetsService, urlQueryService);

  return (
    <div>
      <div>
        <TaxonomyBreadcrumbs />
        {showListHeader && (
          <div className="flex flex-row space justify-between items-center mb-6">
            {isInCatalog && <Typography.Title style={{ margin: 0 }}>{title}</Typography.Title>}
            {ListHeaderControls && <ListHeaderControls />}
          </div>
        )}
      </div>
      <TaxonomyListFilterProvider facetSectionsConfiguration={facets.facetConfiguration}>
        <Card
          className={`relative min-h-[73vh] ${isInMatching ? "" : "-mx-6 md:mx-0"}`}
          bordered={false}
          style={!minMD ? { borderRadius: 0 } : undefined}
          bodyStyle={isInMatching ? { padding: 0 } : undefined}
        >
          {facets.isFacetsExist && (
            <Drawer
              title={isFacetPopover && translations["item.table.facets.title"]}
              placement="left"
              closable={isFacetPopover}
              onClose={closeFacets}
              open={isFacetsOpen}
              getContainer={false}
              mask={false}
              width={264}
              className={styles.drawer}
              headerStyle={{ padding: "20px 12px" }}
              bodyStyle={{ padding: 0 }}
              contentWrapperStyle={{
                position: "sticky",
                top: getFacetsPos().top,
                height: getFacetsPos().height,
                maxHeight: "100%",
                ...(!isFacetPopover && {
                  boxShadow: "none",
                  borderRight: `1px solid ${token.colorBorder}`,
                }),
                borderRadius: `${token.borderRadius}px 0 0 ${token.borderRadius}px`,
                overflow: "hidden",
              }}
              extra={
                <Button
                  icon={<SyncOutlined />}
                  size="small"
                  onClick={resetFacetsSearchParams} />
              }
            >
              <Alert.ErrorBoundary>
                <facets.FacetContainer />
              </Alert.ErrorBoundary>
            </Drawer>
          )}
          <div
            className={clsx(
              "w-full min-w-0 transition-all duration-300",
              isFacetsOpen && !isFacetPopover && "pl-[264px]"
            )}
          >
            <div className="flex gap-4 mb-4">
              {facets.isFacetsExist && (
                <Button icon={<FilterOutlined />} onClick={toggleFacets}>
                  {translations["matching.table.facets.button"]}
                </Button>
              )}
              <div className="grow"><TaxonomySearch /></div>
            </div>
            <facets.FacetSet urlQueryService={urlQueryService} onClearFacet={handleClearFacet} className="mb-4" />
            <TaxonomyTableModule taxonomyClass={taxonomyClass} title={title} />
          </div>
        </Card>
      </TaxonomyListFilterProvider>
    </div>
  );
};
